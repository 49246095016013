import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { useUID } from '@twilio-paste/uid-library';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Heading } from '@twilio-paste/heading';
import { Paragraph } from '@twilio-paste/paragraph';
import { Button } from '@twilio-paste/button';
import { Grid, Column } from '@twilio-paste/grid';
import { Modal, ModalBody, ModalFooter, ModalFooterActions, ModalHeader, ModalHeading } from '@twilio-paste/modal';
import { FormLabel, FormInput, Select, Option } from '@twilio-paste/form';
import Changelog from '@twilio-paste/modal/CHANGELOG.md';
import { DoDont, Do, Dont } from '../../../components/DoDont';
import { Codeblock } from '../../../components/codeblock';
import { SidebarCategoryRoutes } from '../../../constants';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { defaultExample, initialFocusExample, wideExample } from '../../../component-examples/ModalExamples';
export const pageQuery = graphql`
  {
    allPasteComponent(filter: {name: {eq: "@twilio-paste/modal"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/components/modal/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name={props.pageContext.frontmatter.title} categoryRoute={SidebarCategoryRoutes.COMPONENTS} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/components/modal" storybookUrl="/?path=/story/components-modal--default" abstractUrl="https://share.goabstract.com/57f1068b-7265-4102-84fa-0b2bf70214e2" data={props.data.allPasteComponent.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <h3>{`About Modals`}</h3>
        <p>{`A Modal is a dialog that appears over the main content and moves the system into a special mode requiring user interaction. Modals are typically used to focus a user's attention for any of these scenarios:`}</p>
        <ul>
          <li parentName="ul">{`When you want to capture information from the user without having them leave the parent page.`}</li>
          <li parentName="ul">{`When you want to show additional information to the user without losing context of the parent page.`}</li>
        </ul>
        <p>{`If you need to `}<em parentName="p">{`interrupt`}</em>{` a user's current task in order to make them perform an action, use an Alert Dialog (not available yet) instead. An Alert Dialog ensures the user acknowledges the information in the dialog but is visually similar to a Modal.`}</p>
        <p>{`Users cannot interact with content outside an active modal window until the user explicitly closes it. When the modal opens, focus moves to an element contained in it (by default, the first element that can be focused) in the modal, and the focus should stay in and cycle through the modal's content. Focus shouldn't return to the underlying page until the user closes the modal. This can happen in any of the following ways:`}</p>
        <ul>
          <li parentName="ul">{`Presses the Esc key`}</li>
          <li parentName="ul">{`Presses the close "x" button in the Modal header`}</li>
          <li parentName="ul">{`Presses a "Cancel" button in the Modal footer`}</li>
          <li parentName="ul">{`Clicks outside of the Modal`}</li>
          <li parentName="ul">{`Performs another action that closes the Modal`}</li>
        </ul>
        <p>{`You can compose a Modal content area to support your use case, but elements such as Paragraph and Form Input are commonly used. See examples for common Modal patterns and dos/don'ts.`}</p>
        <h3>{`Accessibility`}</h3>
        <ul>
          <li parentName="ul">{`All elements required to interact with the modal, including closing or acknowledging it, are contained in the modal since they trap focus, and users can't interact with the underlying page.`}</li>
          <li parentName="ul">{`Tabbing through a Modal will cycle through its content in the same way it does on a page. A Modal also supports pressing the Escape key to close the Modal.`}</li>
          <li parentName="ul">{`The element that serves as the modal container has a role of dialog.`}</li>
          <li parentName="ul">{`The modal must be labelled. It can be labelled either by:`}<ul parentName="li">
              <li parentName="ul">{`Setting a value for the `}<inlineCode parentName="li">{`aria-labelledby`}</inlineCode>{` property that refers to a visible `}<inlineCode parentName="li">{`ModalHeading`}</inlineCode>{`.`}</li>
              <li parentName="ul">{`Providing a label directly specifing by `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` attribute on the Modal container.`}</li>
            </ul></li>
        </ul>
        <h2>{`Examples`}</h2>
        <h3>{`Default Modal`}</h3>
        <p>{`A modal is composed of:`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`Header`}</strong>{` — Headers include a Heading and close button. Heading text should be concise (2-4 words and no more than one line) and describe the information or action the modal is presenting.`}</li>
          <li parentName="ul"><strong parentName="li">{`Body`}</strong>{` — This container has no content requirements and allows you to compose a Modal as you need. Common components you might use include supporting body copy (with Paragraph) and form elements.`}</li>
          <li parentName="ul"><strong parentName="li">{`Footer`}</strong>{` — This contains all possible modal actions aligned to the right side of the modal (by default) to show users they're progressing through their task, whether that's on the parent page, on a new page, or in another step in the modal.`}</li>
        </ul>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">Provide an accessible label</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    Be sure to assign the <inlineCode>aria-labelledby</inlineCode> property on the modal container to the id if the
    modal heading.
  </CalloutText>
        </Callout>
        <p>{`A Modal has a default width of 608 px to allow for an optimal reading length at 70-75 characters per line at default Paragraph size at 100% magnification. At viewports smaller than 608 px, the Modal will fill the width of the viewport and pin to the bottom. The Modal will grow in height with the amount of content in it. Once the modal reaches 90% of the height of the viewport, the body will begin to scroll.`}</p>
        <LivePreview scope={{
          Modal,
          ModalBody,
          ModalFooter,
          ModalFooterActions,
          ModalHeader,
          ModalHeading,
          useUID,
          Button,
          FormLabel,
          Select,
          Option,
          Paragraph
        }} noInline mdxType="LivePreview">
  {defaultExample}
        </LivePreview>
        <h3>{`Wide Modal`}</h3>
        <p>{`Use a wide modal (816px) when your content requires it. For example, you may need to place a two-column Grid layout or video player in a wide modal.`}</p>
        <p>{`At viewports smaller than 816 px, the Modal will shift to the default width (608px). The wide Modal follows all other sizing behavior as the default Modal.`}</p>
        <LivePreview scope={{
          Modal,
          ModalBody,
          ModalFooter,
          ModalFooterActions,
          ModalHeader,
          ModalHeading,
          useUID,
          Button,
          Box,
          Text,
          Grid,
          Column,
          FormLabel,
          FormInput
        }} noInline mdxType="LivePreview">
  {wideExample}
        </LivePreview>
        <h3>{`Setting initial focus`}</h3>
        <p>{`When a Modal opens user focus is set inside the Modal. By default, user focus will be set on the first focusable element which is the close button. You can choose to explicitly set focus to any focusable UI control inside the modal.`}</p>
        <p>{`To set a different initial focus target, set the `}<inlineCode parentName="p">{`initialFocusRef`}</inlineCode>{` prop on the `}<inlineCode parentName="p">{`Modal`}</inlineCode>{` container to a `}<inlineCode parentName="p">{`ref`}</inlineCode>{` of a focusable element inside the Modal.`}</p>
        <LivePreview scope={{
          Modal,
          ModalBody,
          ModalFooter,
          ModalFooterActions,
          ModalHeader,
          ModalHeading,
          useUID,
          Button,
          Box,
          Text,
          Grid,
          Column,
          FormLabel,
          FormInput
        }} noInline mdxType="LivePreview">
  {initialFocusExample}
        </LivePreview>
        <h2>{`Composition Notes`}</h2>
        <p>{`Include a Header, Body, and Footer in your modal.`}</p>
        <p>{`Modal Header text should be concise (2-4 words), start with a verb, and clearly describe the information or action the modal presents.`}</p>
        <p>{`Modal Body should have 1 main section of content that's described by the text in the Modal Header. If you do need to create multiple sections of content with Headings in Modal Body, use a `}<a parentName="p" {...{
            "href": "/components/heading#heading-as-h-2-with-heading-40-variant"
          }}>{`Heading with the `}<inlineCode parentName="a">{`heading40`}</inlineCode>{` variant`}</a>{`.`}</p>
        <p>{`Modal Footers should include 1–2 actions aligned to the right side of the modal. Place the primary action farthest on the right to indicate to users they're progressing through their task.`}</p>
        <h3>{`Footer actions alignment`}</h3>
        <p>{`By default the footer actions are aligned to the right of the modal using the Flexbox property `}<inlineCode parentName="p">{`justify-content`}</inlineCode>{`. This should be the most common configuration you should choose.`}</p>
        <LivePreview scope={{
          ModalFooter,
          ModalFooterActions,
          Button
        }} mdxType="LivePreview">
  {`<ModalFooter>
  <ModalFooterActions>
    <Button variant="secondary">Cancel</Button>
    <Button variant="primary">Save</Button>
  </ModalFooterActions>
</ModalFooter>`}
        </LivePreview>
        <h4>{`Footer actions left alignment`}</h4>
        <p>{`Sometimes you may need to do left alignment of buttons. This is less common. To do so use the `}<inlineCode parentName="p">{`justify`}</inlineCode>{` property on the `}<inlineCode parentName="p">{`ModalFooterActions`}</inlineCode>{` component and set it to `}<inlineCode parentName="p">{`start`}</inlineCode>{`.`}</p>
        <LivePreview scope={{
          ModalFooter,
          ModalFooterActions,
          Button
        }} mdxType="LivePreview">
  {`<ModalFooter>
  <ModalFooterActions justify="start">
    <Button variant="secondary">Cancel</Button>
    <Button variant="primary">Save</Button>
  </ModalFooterActions>
</ModalFooter>`}
        </LivePreview>
        <h4>{`Footer actions directional alignment`}</h4>
        <p>{`By using both alignment types, you are able to create directional alignments of footer actions.`}</p>
        <LivePreview scope={{
          ModalFooter,
          ModalFooterActions,
          Button
        }} mdxType="LivePreview">
  {`<ModalFooter>
  <ModalFooterActions justify="start">
    <Button variant="secondary">Back</Button>
  </ModalFooterActions>
  <ModalFooterActions>
    <Button variant="secondary">Cancel</Button>
    <Button variant="primary">Save</Button>
  </ModalFooterActions>
</ModalFooter>`}
        </LivePreview>
        <h3>{`When to use Modal`}</h3>
        <p>{`Use Modals to`}</p>
        <ul>
          <li parentName="ul">{`Request the user enter information to continue the current process.`}</li>
          <li parentName="ul">{`Guide the user through a complex workflow with a series of steps, while still maintaining context of the task that initiated the modal.`}</li>
        </ul>
        <p>{`If you need to show an important warning to prevent or correct critical errors, use an `}<a parentName="p" {...{
            "href": "/components/alert-dialog"
          }}>{`Alert Dialog`}</a>{` instead.`}</p>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Use a clear and concise heading to describe the purpose of the Modal." preview={false} mdxType="Do" />
  <Dont title="Don't" body="Don't go into unnecessary detail in the heading about the purpose of the Modal." preview={false} mdxType="Dont" />
        </DoDont>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Keep the main call to action in the footer. If you're asking the user to perform an action, use a primary action (primary button or destructive button). If you need only to give the user a way to close the Modal, use a secondary button or rely on the close 'x' button." preview={false} mdxType="Do" />
  <Dont title="Don't" body="Don't put buttons that close the Modal, or confirm and submit content, in the body." preview={false} mdxType="Dont" />
        </DoDont>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Use at most one primary and one secondary action in a Modal. Make the secondary action a 'Cancel' button, especially when you want to give users an explicit choice to decline the primary action. This is especially useful when the primary action is a destructive action." preview={false} mdxType="Do" />
  <Dont title="Don't" body="Don't put more than one primary action in the footer. Avoid using the secondary button for an action that isn't 'Close' or 'Cancel' since users will be used to pressing a secondary button that escapes the Modal. If you need to provide another action, add a third button to the footer, or consider showing it elsewhere." preview={false} mdxType="Dont" />
        </DoDont>
        <h2>{`Anatomy`}</h2>
        <h3>{`General`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default token`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Modifiable?`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`background-color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-background-body`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`border-color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-background-body`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`border-radius`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`border-radius-20`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`drop-shadow`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`shadow-card`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`modal-width`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`default: size-60, wide: size-80`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`overlay-color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-background-overlay`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
        <h3>{`ModalHeader`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default token/child`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Modifiable?`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Heading`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`h3`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`close-icon-color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-text-weak`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`close-icon-size`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-60`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-50`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`bottom-border-width`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`border-width-10`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`bottom-border-color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-border-lighter`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
        <h3>{`ModalBody`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default token`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Modifiable?`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-50`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
        <h3>{`ModalFooter`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default token`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Modifiable?`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-50`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`top-border-width`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`border-width-10`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`top-border-color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-border-lighter`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/modal - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Usage`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {useUID} from '@twilio-paste/uid-library';
import {Button} from '@twilio-paste/button';
import {Modal, ModalBody, ModalFooter, ModalFooterActions, ModalHeader, ModalHeading} from '@twilio-paste/modal';

const ModalTrigger = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const modalHeadingID = useUID();

  return (
    <div>
      <Button variant="primary" onClick={handleOpen}>
        New Project
      </Button>
      <Modal ariaLabelledby={modalHeadingID} isOpen={isOpen} onDismiss={handleClose} size="default">
        <ModalHeader>
          <ModalHeading as="h3" id={modalHeadingID}>
            Create new project
          </ModalHeading>
        </ModalHeader>
        <ModalBody></ModalBody>
        <ModalFooter>
          <ModalFooterActions>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary">Submit</Button>
          </ModalFooterActions>
        </ModalFooter>
      </Modal>
    </div>
  );
};
`}</code></pre>
        <h4>{`Modal Props`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`children`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`isOpen`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onDismiss`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Function() => void`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`allowPinchZoom?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`true`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`oneOf(['default', 'wide'])`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`initialFocusRef?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`RefObject`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ariaLabelledby`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`__console_patch`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Enable to fix Console's marginBottom bug when modal opens`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`ModalHeader Props`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`children`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`ModalHeading Props`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`children`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`as`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6'])`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`ModalBody Props`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`children`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`ModalFooter Props`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`children`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`ModalFooterActions Props`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`children`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`justify?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`oneOf(['start', 'end'])`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
          </tbody>
        </table>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      